@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --selection: #d3eaff;           /* ::selections */
  --main: #fff;                   /* Text colors on dark-blue */
  --background: #BDE0FE;          /* background */
  --text: #125377;                /* Headings/Text  */ 
  --buttons: #276e97;             /* Btns */
  --buttons-hovers: #4190be;      /* Btns:hovers */
  --containers: #90BAE1;          /* General Containers */
  --links: #ff8c00;               /* Links */
  
  --general-radius: 10px;  
}

html,body{
  overflow-x: hidden;
}

body {
  margin: 0;
  background: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  color: var(--text);
  font-family: 'poppins', 'Helvetica Neue', sans-serif;
}
*::selection{
  background-color: var(--selection);
  color: var(--text) !important;
  opacity: 1 !important; 
}


/* General */
.not-found {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* Text Fonts */
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}
h1 { font-size: 100px; line-height: 100px;}
h2 { font-size: 64px; line-height: 64px;}
h3 { font-size: 32px; line-height: 32px;}
h4 { font-size: 28px; line-height: 28px;}
h5 { font-size: 24px;}
h6 { font-size: 22px; line-height: 22px;}
p { font-size: 18px;}

/* Buttons and Bars */
.nav-btn {
  color: var(--main) !important;
  background: var(--buttons);
  font-weight: 500 !important;
  padding: 6px 18px;
  border: none;
  border-radius: var(--general-radius);
}
.btn-general {
  text-decoration: none;
  width: 200px;
  padding: 12px 24px;
  border: none;
  font-size: 18px;
  color: var(--links);
  border-radius: var(--general-radius);
}
.nav-btn:hover,
.btn-general:hover {
  background: var(--buttons-hovers);
  cursor: pointer;
}
.heading-bar {
  background: linear-gradient(to right, #508EB1, var(--text));
  padding: 8px 24px;
  border-radius: var(--general-radius);
  margin-bottom: 0;
}
.heading-bar h5 {
  color: var(--main);
}
.icon {
  fill: var(--buttons);
}
.icon:hover{
  fill: var(--buttons-hovers);
}

/* Navigation Bar */
.navbar {
  background: var(--containers);
  display: grid;
  height: 60px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  grid-template-rows: 100%;
  grid-template-columns: 2fr 1fr;

  position: fixed;
  top: 0;
  z-index: 2;
}
.navbar *{
  font-weight: 500;
  color: var(--text);
  text-decoration: none;
}
.navMain {
  font-size: 32px;
}
.nav-left {
  justify-self: flex-end;
  position: relative;
  left: 8vw;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.nav-right {
  justify-self: flex-end;
  position: relative;
  right: 8vw;
}
.nav-icon {
  margin-right: 16px;
}
.nav-name {
  margin-right: 20px;
}
.nav-item {
  justify-content: center;
  padding: 4px 16px;
  font-size: 20px;
  border-radius: var(--general-radius);
}
.nav-item:hover {
  color: var(--main);
  background: var(--buttons-hovers);
}

/* Main */
/* Frequently Used Divs */
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.row {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.split-50 {
  width: 50%;
}

/* Profile Card */
.container-card {
  width: 400px !important;
}
.card-profile {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.profile {
  width: 400px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-icons {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
  gap: 40px;
  background: var(--containers);
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Main */
.main {
  width: 84vw;
  margin: 100px auto 40px;
}

/* Landing Page */
.landing {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.landing h1{
  font-size: 7vw;
  font-size: min(7vw, 114px); 
  line-height: calc(7vw + 40px);
}
.icon-arrow {
  position: relative;
  top: 2px;
  margin:10px;
  fill: var(--links);
}
.links {
  text-decoration: none;
  color: var(--links);
  font-size: 20px;
  margin-right: 20px;
  width: fit-content;
}

/* About Page */
/* Summary */
.tb-summary {
  margin: 20px 0 0 0;
}
.tb-summary th {
  text-align: left;
  padding-right: 20px;
}
.tb-summary td {
  text-align: left;
  padding-left: 20px;
}

.section .column {
  gap: 10px;
}
.sub-header {
  margin-top: 20px;
}
.events {
  margin: 0 0 0;
}
.events {
  padding-inline-start: 20px;
}
.container-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.icon-skills {
  width: 60px;
}

/* Project Page */
.descriptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.snippets {
  width: fit-content;
}
.snippet {
  width: 500px;
  place-self: center;
  border-radius: var(--general-radius);
  border: 4px solid var(--text);
}

.otherLinks {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

/* Contact Form */
#contactForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.form-section {
  display: flex;
  width: 100%;
  justify-content: left;
  gap: 20px;
}
.form-control {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: var(--general-radius);
  background: #eaf4fd;
  color: var(--text);
}
.form-control:focus {
  border: none;
}
::placeholder {
  color: var(--text);
}
textarea {
  resize: vertical;
  color: var(--text);
}

/* Footer */
footer {
  margin-top: 0;
}

.cr-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  background-color: var(--containers);
  color: var(--text);
  height: 32px;
  font-size: 16px;
}
.time-log {
  text-align: left;
  width: 50%;
  margin-left: 8%;
}
.copyright {
  text-align: right;
  width: 50%;
  margin-right: 8%;
}

@media screen and (min-width: 950px) {
  .minimal {
    display: none;
  }
  .menu-icon {
    display: none !important;
  }
}

@media screen and (max-width: 950px) {
  .desktop {
    display: none;
  }

  .landing-main,
  .row {
    flex-direction: column;
  }

  /* Navigation */
  .navLinks,
  .nav-btn {
    display: none !important;
  }
  .navbar {
    grid-template-columns: 2fr 1fr;
  }

  .column{
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .row {
    width: 84vw;
    text-align: center;
    justify-content: center;
  }

  /* General */
  .heading-bar {
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .btn-general {
    align-self: center;
  }

  /* Card */
  .container-card {
    width: 100% !important;
  }
  .card-profile {
    width: 84vw;
    max-width: 500px;
    margin: auto;
  }
  .profile {
    width: 100% !important;
  }

  /* Landing Page */
  .landing-main {
    width: 80vw;
    gap: 40px;
    max-width: 500px;
  }
  .landing {
    max-width: 500px;
    margin: auto;
  }
  .landing h1 {
    font-size: 44px !important;
    line-height: 50px;
  }
  .links {
    place-self: center;
  }

  /* About */
  .tb-summary {
    max-width: 500px;
    margin: auto;
    margin-top: 40px;
  }
  .container-skills {
    max-width: 500px;
    margin: auto;
    justify-content: center;
    margin-top: 20px;
  }
  .sub-header {
    margin-top: 40px;
  }
  .events{
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
  .events li {
    list-style: none;
  }

  /* Projects */
  #projects .descriptions,
  #projects .heading-bar {
    max-width: 950px;
    margin: auto;
  }
  #projects .row,
  #projects .col {
    text-align: justify;
  }
  #projects .row {
    flex-direction: column-reverse;
    gap: 20px;
  }

  #projects .row.descriptions{
    max-width: 950px !important;
  }

  /* Form */
  .form-section {
    justify-content: center;
  }
  /* Copyright */
  .copyright {
    text-align: center;
    width: 100vw;
    right: 0;
  }
}

  /* Menu Icons */
  .btn-menu {
    background: none;
    border: none;
  }
  .btn-menu:hover {
    border: none;
    cursor: pointer;
  }
  .menu-icon {
    position: relative;
    top: 6px;
    z-index: 3;
  }
  .line1 , .line2 , .line3{
    width: 30px;
    height: 3px;
    margin-bottom: 6px;
    background-color: var(--text);
    border-radius: 50px;
    transition: 0.2s;
  }
  .line3{
    width: 20px;
  }
  .active .line1{
    transform: translate(0px , 9px) rotate(45deg);
  }
  .active .line2{
    opacity: 0;
  }
  .active .line3{
    width: 30px;
    transform: translate(0px , -9px) rotate(-45deg);
  }
  .minimal-navLinks {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background: var(--buttons-hovers);
    width: 200px;
    height: fit-content;
    padding: 10px 10px;
    padding-top: 40px;
    border-radius: var(--general-radius);
    z-index: 2;
  }
  .minimal .nav-item {
    display: none;
  }